<template>
  <b-col cols="12" lg="6" class="bg-primary-4 hp-bg-color-dark-90">
    <b-row
      class="hp-image-row h-100 px-8 px-sm-16 px-md-0 pb-32 pb-sm-0 pt-32 pt-md-0"
    >
      <b-col cols="12" class="hp-logo-item m-16 m-sm-32 m-md-64 w-auto px-0">
        <img
          :src="
            this.$store.state.themeConfig.theme == 'dark'
              ? require('@/assets/img/logo/unoobs_logo.png')
              : require('@/assets/img/logo/unoobs_logo.png')
          "
          alt="Logo"
          width="15%"
        />
      </b-col>

      <b-col cols="12">
        <b-row align-v="center" align-h="center" class="h-100">
          <b-col cols="12" md="8" class="hp-bg-item text-center mb-32 mb-md-0">
            <img
              class="m-auto image"
              :src="
                this.$store.state.themeConfig.theme == 'dark'
                  ? require('@/assets/img/pages/authentication/core_values.png')
                  : require('@/assets/img/pages/authentication/core_values.png')
              "
              alt="Background Image"
            />
          </b-col>

          <b-col cols="12" md="11" lg="10" class="hp-text-item text-center">
            <h2 class="text-primary-1 hp-text-color-dark-0 mx-16 mx-lg-0 mb-16">
              Very good things are waiting for you 🤞
            </h2>

            <p class="mb-0 text-black-80 hp-text-color-dark-30">
              To access the Undisputed Noobs member portal you must have created
              your account and submitted your application
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 991px) {
  .image {
    width: 50%;
  }
}
</style>
